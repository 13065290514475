import React, { useEffect } from "react";
import ConnectEventCombinedComponent from "../common/ConnectEventPageLayouts/ConnectEventCombined/ConnectEventCombinedComponent";
import EnableAdobeLaunch from "../../../../common/modules/adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
    pageName: "event-page",
    listName: "event-page"
};

const EventComponent = () => {
    useEffect(() => {
        EnableAdobeLaunch(ADOBE_DATA)
    }, [])
    return (
        <ConnectEventCombinedComponent mainPage="Event" />
    )
}

export default EventComponent;