import "./FooterComponent.scss";
import { PeopleHelp, NexusGold, NexusPowered } from "../../assets/images";

const FooterComponent: React.FC = () => {
  return (
    <div className="footer-container-main">
      {/* <div className="footer-help-container">
        <div className="footer-help-left">
          <img src={PeopleHelp} alt="PeopleHelp" className="people-help" />
          <div className="help-left-content">
            <h2>Need help?</h2>
            <h4>Reach out today for support and guidance.</h4>
            <div className="help-contact">nexus.support@ey.com</div>
          </div>
        </div>
        <div className="footer-help-right">
          <img src={NexusGold} alt="Nexus" className="nexus-gold" />
        </div>
      </div> */}

      <div className="footer-links-container">
        <div className="footer-links-left">
          <img
            src={NexusPowered}
            alt="NexusPowered"
            className="nexus-powered"
          />
        </div>
        <div className="footer-links-right">
          <div className="footer-links">
            <div className="footer-links-title">Quick Links</div>
            <div className="footer-links-list">
              <div className="footer-link">Amsterdam</div>
              <div className="footer-link">Dordrecht</div>
              <div className="footer-link">Eindhoven</div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-copy-container">
        Copyright@Ernst&Young,LLC. All right reserved
      </div>
    </div>
  );
};

export default FooterComponent;
