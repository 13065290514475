import { useEffect, useState } from "react";
import './AdoptDetailsComponent.scss'
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import SharePointService from "../../../../common/modules/service/SharePointService";
import AdoptSubPageBannerComponent from "../common/AdoptSubPageLayouts/AdoptSubPageBanner/AdoptSubPageBannerComponent";
import AdoptSubPageBodyComponent from "../common/AdoptSubPageLayouts/AdoptSubPageBody/AdoptSubPageBodyComponent";
import { AdoptPageStaticDetails, AdoptSubPageStaticContent } from "../../../../common/models/AdoptPageStaticDetails";
import { Accelerator } from "../../../../common/models/Accelerators";
import { NexusTabBar } from "@nexus/react/dist/components";
import AdoptSubPageFeaturesComponent from "../common/AdoptSubPageLayouts/AdoptSubPageFeatures/AdoptSubPageFeatures";
import { AdoptPageDetails } from "../../../../common/models/AdoptPageDetails";
import AdoptSubPageStrategyComponent from "../common/AdoptSubPageLayouts/AdoptSubPageStrategy/AdoptSubPageStrategy";
import { useParams } from "react-router-dom";
import TrainingNHelpComponent from "../common/Training&HelpComponent/Training&Help";
import EnableAdobeLaunch from "../../../../common/modules/adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
    pageName: "adopt-details-page",
    listName: "adopt-details-page"
};

const AdoptDetailsComponent = (props: {
    isAuthenticated: boolean
}) => {

    const params = useParams();
    const adoptItemId = params.id;

    const { instance, accounts } = useMsal();
    // const isAuthenticated = useIsAuthenticated();

    const [adoptPageStaticDetails, setAdoptPageStaticDetails] = useState<AdoptPageStaticDetails[]>([{}]);
    const [adoptItemDetails, setadoptItemDetails] = useState<Accelerator>({});
    const [adoptPageDetails, setAdoptPageDetails] = useState<AdoptPageDetails[]>([{}]);
    const [filteredAdoptPageStaticDetails, setFilteredAdoptPageStaticDetails] = useState<AdoptSubPageStaticContent>({});
    const [filteredAdoptPageDetails, setFilteredAdoptPageDetails] = useState<AdoptPageDetails[]>([{}]);
    const [trainingInfo, setTrainingInfo]: any[] = useState([])
    const [helpInfo, setHelpinfo]: any[] = useState([])
    const [persona, setPersona] = useState<any>([]);

    //let spService = new SharePointService()    
    let personaList: any = [];

    function fetchAdopFeatureAndStrategy(spService: any) {
        spService.getAdoptPageDetails(instance, accounts, adoptItemId)
            .then((response: any) => response.json())
            .then((data: any) => {
                setAdoptPageDetails(data.value);
                const selectedPersona = preparePersona(data.value);
                setFilteredAdoptPageDetails(filterDetailsByPersona(selectedPersona, data.value));

                fetchAdopPageStaticDetails(spService);
            }).catch((error: any) => {
                console.error(error);
            });
    }

    function fetchAdopPageStaticDetails(spService: any) {
        spService.getAdoptPageStaticDetails(instance, accounts, adoptItemId)
            .then((response: any) => response.json())
            .then((data: any) => {
                setAdoptPageStaticDetails(data.value);
                const selectedPersona = preparePersona(data.value);
                const filteredData = filterDetailsByPersona(selectedPersona, data.value)
                setFilteredAdoptPageStaticDetails(parseJson(filteredData[0].Content));
            }).catch((error: any) => {
                console.error(error);
            });;
    }

    function fetchAdopItem(spService: any) {
        spService.getAccelerators(instance, accounts)
            .then((response: any) => response.json())
            .then((data: any) => {
                const adoptItem: any = data.value.filter(function (item: any) {
                    return item.Id == adoptItemId;
                })[0];
                setadoptItemDetails(adoptItem);
            }).catch((error: any) => {
                console.error(error);
            });;
    }

    function preparePersona(data: any) {

        let unique: any = [];
        if (personaList.length > 0) {
            return personaList[0].name;
        }
        else {
            data.forEach((item: any, index: any) => {
                const personaExist = unique?.find((persona: any) => persona.name === item.Persona);
                if (!personaExist && item.Persona !== undefined && item.Persona !== null) {
                    unique.push({
                        name: item.Persona
                    });
                }
            });

            unique = unique?.sort((first: any, second: any) => first.name.localeCompare(second.name));
            if (unique.length > 0)
                unique[0].isActive = true;

            setPersona(unique);
            personaList = unique;
            return unique.length > 0 ? unique[0].name : undefined;
        }
    }

    const fetchDevPortalStaticContents = (spService: any) => {

        spService.getDevPortalStaticContents(instance, accounts)
            .then((response: any) => response.json())
            .then((data: any) => {
                let trainingData: any = []
                let helpingData: any = []
                data.value.map((item: any) => {
                    if (item.Title === "SectionTraining") {
                        const itemData = JSON.parse(item.Content)
                        trainingData.push(itemData)
                    }
                })
                data.value.map((item: any) => {
                    if (item.Title === "SectionHelp") {
                        const helps = JSON.parse(item.Content)
                        helpingData.push(helps)
                    }
                })
                setTrainingInfo(trainingData)
                setHelpinfo(helpingData)
            }).catch((err: any) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (props.isAuthenticated) {
            EnableAdobeLaunch(ADOBE_DATA)
            let spService = new SharePointService();
            fetchAdopFeatureAndStrategy(spService);
            fetchAdopItem(spService);
            fetchDevPortalStaticContents(spService);
        }
    }, [])

    const setActive = (index: number) => {

        const selectedTab = [...persona];
        selectedTab.forEach((option) => {
            option.isActive = false;
        });
        selectedTab[index].isActive = true;
        setPersona(selectedTab);

        setFilteredAdoptPageDetails(filterDetailsByPersona(selectedTab[index].name, adoptPageDetails));
        const filteredData = filterDetailsByPersona(selectedTab[index].name, adoptPageStaticDetails)
        if (filteredData.length > 0)
            setFilteredAdoptPageStaticDetails(parseJson(filteredData[0].Content));
        else
            setFilteredAdoptPageStaticDetails({});

    };

    const parseJson = (data: any) => {
        try {
            // Attempt to parse the JSON string
            return JSON.parse(data);

        } catch (e) {
            return {}
        }
    };

    function filterDetailsByPersona(selectedPersona: any, data: any) {
        if (selectedPersona != null && selectedPersona !== undefined) {
            return data?.filter((item: any) => item.Persona == selectedPersona);
        }
        else {
            return data;
        }
    }

    return (

        <div className="overflow-hidden">
            <AdoptSubPageBannerComponent adoptItemDetails={adoptItemDetails} adoptPageStaticDetails={filteredAdoptPageStaticDetails} ></AdoptSubPageBannerComponent>

            {persona.length > 0 ? (
                <div className="nexus-row adopt-tab-control">
                    <NexusTabBar>
                        <span>

                            {persona?.map((option: any, index: any) =>
                                option?.isActive ? (
                                    <button
                                        data-testid="tab-bar-active"
                                        key={index}
                                        className="nexus-link nexus-active"
                                        onClick={(_event) => setActive(index)}
                                    >
                                        {option?.name}
                                    </button>
                                ) : (
                                    <button
                                        data-testid="tab-bar"
                                        key={index}
                                        className="nexus-link"
                                        onClick={(_event) => setActive(index)}
                                    >
                                        {option?.name}
                                    </button>
                                )
                            )}
                        </span>
                    </NexusTabBar>
                </div>
            ) : ""}

            <AdoptSubPageBodyComponent adoptPageStaticDetails={filteredAdoptPageStaticDetails}></AdoptSubPageBodyComponent>
            <AdoptSubPageFeaturesComponent adoptPageStaticDetails={filteredAdoptPageStaticDetails} featureCount={adoptItemDetails?.FeatureCount} adoptSubPageFeatures={filteredAdoptPageDetails?.filter((item) => item.Section == "Feature")}></AdoptSubPageFeaturesComponent>
            <AdoptSubPageStrategyComponent adoptPageStaticDetails={filteredAdoptPageStaticDetails} adoptSubPageStrategies={filteredAdoptPageDetails?.filter((item) => item.Section == "Strategy")}></AdoptSubPageStrategyComponent>

            <div className="nexus-flex-row training-container">
                {Object.keys(trainingInfo).length > 0 ? <TrainingNHelpComponent trainings={trainingInfo} helpData={helpInfo} /> :
                    <p>loading...</p>
                }

            </div>
        </div>

    )
}

export default AdoptDetailsComponent