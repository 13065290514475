// msalConfig.ts
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: '1753222e-400e-445d-83f9-81f552e5abef',
    authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',
    redirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,

  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  }
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read', 'AllSites.Read'],
};

export const spTokenRequest = {
  scopes: ['https://sites.ey.com/.default']
}

export const graphTokenRequest = {
  scopes: ['https://graph.microsoft.com/Sites.Read.All'],
}


export const msalInstance = new PublicClientApplication(msalConfig);