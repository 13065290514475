import React, { useEffect } from "react";
import ConnectEventCombinedComponent from "../common/ConnectEventPageLayouts/ConnectEventCombined/ConnectEventCombinedComponent";
import EnableAdobeLaunch from "../../../../common/modules/adobeAnalytics/EnableAdobeLaunch";

const ADOBE_DATA = {
    pageName: "connect-page",
    listName: "connect-page"
};

const ConnectComponent = () => {

    useEffect(() => {
        EnableAdobeLaunch(ADOBE_DATA)
    }, [])

    return (
        <ConnectEventCombinedComponent mainPage="Connect" />
    )
}

export default ConnectComponent;
