import { NexusCard, NexusCardBody, NexusCardBodyImage } from "@nexus/react";
import { AdoptSubPageStaticContent } from "../../../../../../common/models/AdoptPageStaticDetails";
import './AdoptSubPageStrategyComponent.scss'
import { AdoptPageDetails } from "../../../../../../common/models/AdoptPageDetails";
import { useEffect, useState } from "react";
import SharePointService from "../../../../../../common/modules/service/SharePointService";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

const AdoptSubPageStrategyComponent = (props: {
  adoptPageStaticDetails: AdoptSubPageStaticContent
  adoptSubPageStrategies: AdoptPageDetails[]
}) => {

  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [strategyImages, setStrategyImages] = useState<any>([]);
  const [imagesObject, setImages] = useState<any>({});
  let spService = new SharePointService();

  useEffect(() => {
    // checking image length to restrict only one call to getImages.
    const isExist = strategyImages.filter((item:any) => item.Id === props.adoptSubPageStrategies[0].Id)
    if (isAuthenticated && (strategyImages.length === 0 || isExist.length === 0)) {
      getImages();
    }
  }, [props.adoptSubPageStrategies, imagesObject])

  function getImages() {

    if (isAuthenticated) {
      const items: any = [];
      let updatedObject = imagesObject;
      props.adoptSubPageStrategies.forEach((element: any) => {

        items.push({
          Id: element.Id
        });
        setStrategyImages(items);

        if (element.ImgForDevPortal) {
          spService.getAttachments(spService.ListNames.ADOPT_PAGE_DETAILS, element.Id, encodeURI(JSON.parse(element.ImgForDevPortal).fileName), instance, accounts).then((imgData) => {
            const updatedItems = [...items];
            const currentItem = updatedItems.find((item) => item.Id === element.Id);
            currentItem.icon = imgData;

            updatedObject = {
              ...updatedObject,
              [element.Id]: imgData
            };

            // console.log(updatedObject);
            setImages(updatedObject);
            setStrategyImages(items);
          });
        }
      });
    }
  }

  return (
    <div className="adopt-subpage-strategy ">
      <div className="nexus-row">
        <div className="nexus-col-lg-12">
          <h6 className="nexus-h6">{props.adoptPageStaticDetails.StrategicValueSectionHeader?.QuestionText}</h6>

        </div>
        <div className="nexus-col-lg-12 nexus-my-1">
          <h3 className="nexus-h3">{props.adoptPageStaticDetails.StrategicValueSectionHeader?.SectionTitle}</h3>
        </div>
      </div>
      <div className="nexus-row">
        {props.adoptSubPageStrategies.length ? (
          props.adoptSubPageStrategies?.map((item: any, index: any) => (
            <div
              className="nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4  nexus-py-2"
              key={index}
            >
              <NexusCard className="solution-cards mb-4" key={item.index}>
                <NexusCardBody>
                  <div className="nexus-row strategy-img-div">
                    <img
                      src={item.ImgForDevPortal == null || item.ImgForDevPortal == undefined ? "/Containernull.png" : imagesObject[item.Id]} //  strategyImages?.filter((imageItem:any) => imageItem.Id == item.Id)[0]?.icon}
                      alt=""
                      className="strategy-icon"
                    />
                  </div>

                  <div className="nexus-row nexus-mb-2 ">
                    <h5 className="nexus-col-xs-4 nexus-h5 card-heading">
                      {item.Title}
                    </h5>
                  </div>
                  <div className="nexus-row nexus-mb-1">
                    <p className="nexus-col-xs-4 nexus-subtitle-sm card-description" title={item.Description}>
                      {item.Description}
                    </p>
                  </div>
                </NexusCardBody>
              </NexusCard>
            </div>

          ))) : <div className="nexus-col-xs-4 mb-4">
          <div className="nexus-center-xs nexus-mt-4 nexus-h6">
            No data available
          </div>
        </div>}
      </div>
    </div>
  )
}

export default AdoptSubPageStrategyComponent;