import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import SharePointService from "../../../../../../common/modules/service/SharePointService";
import WhatsIncludedCatalogComponent from "../WhatsIncludedCatalog/WhatsIncludedCatalogComponent";
import "./seeAllComponent.scss";
import { NexusBreadcrumb, NexusBreadcrumbItem } from "@nexus/react";
import { Link } from "react-router-dom";

const SeeAllComponent = () => {
    const mainPage = (window.location.href).includes("connect") ? "Connect" : "Event";
    const { instance, accounts } = useMsal();
    const [sellAllBannerData, setSellAllBannerData] = useState({
        BannerDescription: "",
        BannerTitle: "",
        QuestionText: "",
        SectionTitle: ""
    });

    function fetchOnLoad() {
        let spService = new SharePointService();
        spService.getDevPortalPageDetails(instance, accounts).then(response => response.json())
            .then(response => {
                response.value.map((item: any) => {
                    if (item.PageName === mainPage) {
                        const parsedSellAllBannerData = JSON.parse(item.WhatsIncluded);
                        console.log(parsedSellAllBannerData);
                        setSellAllBannerData(parsedSellAllBannerData);
                    }
                })
            })
    }

    function getUrl(url: string) {
        const urlNew = url.split(" ").slice(1, 2).join(" ").toLowerCase()
        if (urlNew === 'component') {
            return 'event'
        } else {
            return urlNew
        }
    }

    useEffect(() => {
        fetchOnLoad();
    }, []);

    return (
        <div className="nexus-col-12">
            <div className="nexus-row see-all-banner-container nexus-p-8">
                <h1 className="nexus-h1 nexus-mb-3">
                    {sellAllBannerData.BannerTitle}
                </h1>
                <p className="nexus-body">
                    {sellAllBannerData.BannerDescription}
                </p>
            </div>
            <div className="nexus-row nexus-pt-4 see-all-breadcrumb">
                <NexusBreadcrumb separator=">">
                    <NexusBreadcrumbItem data-testid="breadcrumb-1">
                        {/* <span>Home</span> */}
                        <Link to={'/'}>Home</Link>
                    </NexusBreadcrumbItem>
                    <NexusBreadcrumbItem data-testid="breadcrumb-2">
                        <Link to={`/nexus/${getUrl(sellAllBannerData.BannerTitle)}`}>
                            {sellAllBannerData.BannerTitle.split(" ").slice(0, 2).join(" ")}
                        </Link>
                        {/* <span>{sellAllBannerData.BannerTitle.split(" ").slice(0, 2).join(" ")}</span> */}
                    </NexusBreadcrumbItem>
                    <NexusBreadcrumbItem data-testid="breadcrumb-3">
                        <span>{sellAllBannerData.BannerTitle.split(" ").slice(1, 3).join(" ")}</span>
                    </NexusBreadcrumbItem>
                </NexusBreadcrumb>
            </div>
            <WhatsIncludedCatalogComponent mainPage={mainPage} cardsPerPage={15} seeAllPresent={false} />
        </div>
    )
}

export default SeeAllComponent;