import { useEffect, useState } from "react";
import { LoaderComponent } from "../../common/components";
import {
  NexusOperatingSystemComponent,
  NexusSearchComponent,
  EyNexusComponent,
  GetStartedComponent,
  ComingSoonComponent,
} from "./components/index";
import {
  getSearchSectionTitle,
  getOperatingSystemHeaderData,
  getOperatingSystemValueData,
  getEyNexusContentData,
  getGetStartedContentData,
  getComingSoonText,
  getCommunityContentData,
  getSectionPrototypingAndSandboxData,
  getSectionTrainingData,
} from "./utils/homeUtils";
import { HomeData } from "./Models/HomeModel";
import { NexusOperating } from "./Models/NexusOperatingModel";
import SharePointService from "../../common/modules/service/SharePointService";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import EnableAdobeLaunch from "../../common/modules/adobeAnalytics/EnableAdobeLaunch";
import PeopleHelp from '../../common/assets/images/PeopleHelp.svg'
import './HomeComponent.scss'

const ADOBE_DATA = {
  pageName: "home",
  listName: "home"
};

const HomeComponent = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [loader, setLoader] = useState<boolean>(false);
  const [homeData, setHomeData] = useState<HomeData | null>(null);
  const [nexusOperatingData, setNexusOperatingData] =
    useState<NexusOperating | null>(null);

  const getLandingPageStaticData = async (spService: any) => {
    setLoader(true);
    // if (isAuthenticated) {
    await spService.getDevPortalStaticContents(instance, accounts).then((response: { json: () => any; }) => response.json())
      .then((res: any) => {
        setHomeData(res)
        getLandingPageData(spService)
      }).catch((err: any) => console.log(err))
    // }
  }

  const getLandingPageData = async (spService: any) => {
    await spService.getDevPortalPageDetails(instance, accounts).then((response: { json: () => any; }) => response.json())
      .then((res: any) => {
        setNexusOperatingData(res);
        setLoader(false);
      }).catch((err: any) => {
        console.error("Error in fetching home data", err);
        setHomeData(null);
        setLoader(false);
      })

  }

  useEffect(() => {
    EnableAdobeLaunch(ADOBE_DATA)
    setLoader(true);
    let spService = new SharePointService();
    getLandingPageStaticData(spService)

    // homeService
    //   .getHomeData()
    //   .then((response) => {
    //     // setHomeData(response);
    //   })
    //   .then(() => {
    //     homeService.getNexusOperatingData().then((response) => {
    //       // setNexusOperatingData(response);
    //       setLoader(false);
    //     });
    //   })
    //   .catch((error) => {
    //     console.error("Error in fetching home data", error);
    //     setHomeData(null);
    //     setLoader(false);
    //   });
  }, []);

  return (
    <>
      {loader && <LoaderComponent />}

      <NexusSearchComponent title={getSearchSectionTitle(homeData)} />

      <NexusOperatingSystemComponent
        headerData={getOperatingSystemHeaderData(homeData)}
        content={getOperatingSystemValueData(nexusOperatingData)}
      />

      <EyNexusComponent content={getEyNexusContentData(homeData)} />

      <GetStartedComponent content={getGetStartedContentData(homeData)} />

      <ComingSoonComponent
        commingSoon={getComingSoonText(homeData)}
        community={getCommunityContentData(homeData)}
        prototyping={getSectionPrototypingAndSandboxData(homeData)}
        training={getSectionTrainingData(homeData)}
      />
      <div className="footer-container-home">
        <div className="footer-help-left">
          <img src={PeopleHelp} alt="PeopleHelp" className="people-help" />
          <div className="help-text">
            <h2>Need Help?</h2>
            <h4>Reach out today for support and guidance.</h4>
            <div className="help-contact">nexus.support@ey.com</div>
          </div>
        </div>
        <div className="footer-help-right">
          {/* <img src={NexusGold} alt="Nexus" className="nexus-gold" /> */}
        </div>
      </div>
    </>
  );
};

export default HomeComponent;
