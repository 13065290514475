import React, { useEffect, useState } from "react";
import { IconTraining, PeopleHelp, NexusGold } from "../../../../../common/assets/images";
import { useNavigate } from "react-router";

export default function TrainingNHelpComponent(props: any) {
    const [trainData, setTrainData] = useState(props.trainings)
    const [helpData, setHelpData] = useState(props.helpData)
    const navigate = useNavigate()

    const trainingHandle = () => {
        navigate('/nexus/training')
    }
    return (
        <>
            <div className="coming-soon-section" onClick={trainingHandle} onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') trainingHandle();
            }}
                tabIndex={0}
                role="button">
                <img src={IconTraining} alt="IconComunity" />
                <h2>{trainData[0].Title}</h2>
                <div className="comingsoon-description">
                    {trainData[0].Description}
                </div>
            </div>
            <div className="footer-help-container">
                <div className="footer-help-left">
                    <img src={PeopleHelp} alt="PeopleHelp" className="people-help" />
                    <div className="help-text">
                        <h2>{helpData[0].Title}</h2>
                        <h4>{helpData[0].Description}</h4>
                        <div className="help-contact">{helpData[0].Email}</div>
                    </div>
                </div>
                {/* <div className="footer-help-right">
                        <img src={NexusGold} alt="Nexus" className="nexus-gold" />
                    </div> */}
            </div>
        </>
    )
}