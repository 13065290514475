import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { useMsal } from "@azure/msal-react";
import { NexusGold, PeopleHelp } from "../../../../common/assets/images";
import "./Slate.scss";
import SharePointService from "../../../../common/modules/service/SharePointService";

const Slate = () => {
    const { instance, accounts } = useMsal();
    let customURL = "";
    const { id } = useParams();
    const search = useLocation().search;

    const [slateURL, setSlateURL] = useState(new URLSearchParams(search).get("url"));

    const [trainingInfo, setTrainingInfo]: any[] = useState([]);
    const [helpInfo, setHelpinfo]: any[] = useState([]);

    const getStaticData = () => {
        let spService = new SharePointService();
        spService.getDevPortalStaticContents(instance, accounts)
            .then(response => response.json())
            .then((data: any) => {
                let trainingData: any = []
                let helpingData: any = []
                data.value.map((item: any) => {
                    if (item.Title === "SectionTraining") {
                        const itemData = JSON.parse(item.Content)
                        trainingData.push(itemData)
                    }
                })
                data.value.map((item: any) => {
                    if (item.Title === "SectionHelp") {
                        const helps = JSON.parse(item.Content)
                        helpingData.push(helps)
                    }
                })
                // console.log(trainingData, helpingData);
                setTrainingInfo(trainingData)
                setHelpinfo(helpingData)
                // console.log(helpingData);
            }).catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        const customTitle = id?.toLowerCase().split(" ").join("-");
        customURL = `https://nexus-portal-dev.sbp.eyclienthub.com/apidocumentation/slate/#${customTitle}`;
        setSlateURL(new URLSearchParams(search).get("url"));
        getStaticData();
    }, []);

    return (
        <>
            <div className="slate-iframe-block">
                {slateURL !== null &&
                    <iframe src={slateURL} className="slate-iframe" height="1024px" width="100%" title="Iframe Example"></iframe>
                }
            </div>
            <div className="nexus-flex-row slate-help-block">
                <div className="nexus-flex-none nexus-ml-4 nexus-mt-3">
                    <img src={PeopleHelp} alt="Nexus" />
                </div>
                {Object.keys(helpInfo).length > 0 &&
                    <div className="nexus-flex-grow nexus-ml-7 nexus-mt-5">
                        <h2 className="nexus-h2">{helpInfo[0].Title}</h2>
                        <h4 className="nexus-h4">{helpInfo[0].Description}</h4>
                        <a href="mailto:Email@Nexus.com" className="email-support">{helpInfo[0].Email}</a>
                    </div>}
                <div className="nexus-flex-1 nexus-mt-6 nexus-mr-3">
                    <img src={NexusGold} alt="Nexus" />
                </div>
            </div>
        </>
    )
}

export default Slate;