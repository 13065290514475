import { NexusCard, NexusCardBody } from "@nexus/react";
import { AdoptSubPageStaticContent } from "../../../../../../common/models/AdoptPageStaticDetails";
import './AdoptSubPageFeaturesComponent.scss'
import { AdoptPageDetails } from "../../../../../../common/models/AdoptPageDetails";

const AdoptSubPageFeaturesComponent = (props: {
    adoptPageStaticDetails: AdoptSubPageStaticContent
    adoptSubPageFeatures: AdoptPageDetails[]
    featureCount: any
}) => {

    return (
        <div className="adopt-subpage-feature">
            <div className="nexus-row ">
                <div className="nexus-col-lg-12">
                    <h6 className="nexus-h6">{props.adoptPageStaticDetails.FeatureSectionHeader?.QuestionText}</h6>

                </div>
                <div className="nexus-col-lg-12 nexus-my-1">
                    <h3 className="nexus-h3">{props.adoptPageStaticDetails.FeatureSectionHeader?.SectionTitle}</h3>
                </div>
            </div>
            <div className="nexus-row  ">
                {
                    props.adoptSubPageFeatures.length ? (
                        props.adoptSubPageFeatures?.slice(0, props.featureCount !== null && props.featureCount !== undefined ? props.featureCount : 3).map((item: any, index: any) => (

                            <div
                                className={props.featureCount % 4 == 0 && props.adoptSubPageFeatures.length % 4 == 0 && props.featureCount !== null ? `nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-3 nexus-col-lg-3 nexus-py-2` : `nexus-col-xs-4 nexus-col-sm-4 nexus-col-md-4 nexus-col-lg-4  nexus-py-2`}
                                key={index}
                            >
                                <NexusCard className="solution-cards mb-4">
                                    <NexusCardBody>
                                        <div className="nexus-row nexus-mb-2 ">
                                            <h5 className="nexus-col-xs-4 nexus-h5 card-heading">
                                                {item.Title}
                                            </h5>
                                            <div className="nexus-pl-1 nexus-py-2 header-border-bottom-container">
                                                <span className="header-border-bottom"></span>
                                            </div>
                                        </div>
                                        <div className="nexus-row nexus-mb-1">
                                            <p className="nexus-col-xs-4 nexus-subtitle-sm card-description" title={item.Description}>
                                                {item.Description}
                                            </p>
                                        </div>
                                    </NexusCardBody>
                                </NexusCard>
                            </div>

                        ))) : <div className="nexus-col-xs-4 mb-4">
                        <div className="nexus-center-xs nexus-mt-4 nexus-h6">
                            No data available
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default AdoptSubPageFeaturesComponent;