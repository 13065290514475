import { Navigate, Route, Routes } from "react-router-dom";
import { AppRouterPaths } from "./AppRouter.const";

import HomeComponent from "../../features/Home/HomeComponent";
import ConnectComponent from "../../features/OperatingSystem/components/Connect/ConnectComponent";
import EventComponent from "../../features/OperatingSystem/components/Event/EventComponent";

import AdoptComponent from "../../features/OperatingSystem/components/Adopt/AdoptComponent";
import SeeAllComponent from "../../features/OperatingSystem/components/common/ConnectEventPageLayouts/SeeAll/seeAllComponent";
import AdoptDetailsComponent from "../../features/OperatingSystem/components/AdoptDetails/AdoptDetailsComponent";
import { useIsAuthenticated } from "@azure/msal-react";
import TrainingPageDetails from "../../features/OperatingSystem/components/Training/Training";
import Slate from "../../features/OperatingSystem/components/NexusSlate/Slate";

const { root, connectMainPage, adoptMainPage, adoptDetailsPage, noMatch, eventMainPage, seeAll, TrainingPage } = AppRouterPaths;



const AppRoutes = () => {

  const isAuthenticated = useIsAuthenticated();
  return (

    <Routes>
      <Route path={`${root}`} element={<HomeComponent />} />
      <Route path={`/nexus/${connectMainPage}`} element={<ConnectComponent />} />
      <Route path={`/nexus/${eventMainPage}`} element={<EventComponent />} />
      <Route path={`/nexus/${adoptMainPage}`} element={<AdoptComponent />} />
      <Route path={`/nexus/${adoptDetailsPage}/:id`} element={<AdoptDetailsComponent isAuthenticated={isAuthenticated} />} />
      <Route path={`/nexus/${TrainingPage}`} element={<TrainingPageDetails />} />
      <Route
        path={`${noMatch}`}
        element={<Navigate to={`${root}`} replace={true} />}
      />
      <Route path={`nexus/connect/${seeAll}`} element={<SeeAllComponent />} />
      <Route path={`nexus/event/${seeAll}`} element={<SeeAllComponent />} />
      <Route path={`nexus/event/${seeAll}/:selecteditem`} element={<SeeAllComponent />} />
      <Route path={`nexus/connect/${seeAll}/:selecteditem`} element={<SeeAllComponent />} />
      <Route path={'/nexus/slate'} element={<Slate />} />
    </Routes>
  )
};

export default AppRoutes;
