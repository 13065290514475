import React, { useEffect, useState } from "react";
import "./HowItsUsedComponent.scss";
import {
  NexusButton,
  NexusCard,
  NexusCardBody,
  NexusCardBodyImage,
  NexusCardFooter,
} from "@nexus/react";
import cardImage from "../../../../../../common/assets/images/microservicesAcceleratorProject.png";

const HowItsUsedComponent = (props: any) => {
  const howItsUsedData = (props.howItsUsedData).toString() !== "[object Object]" ? JSON.parse(props.howItsUsedData) : {};
  const imageData = (props.imageData).toString() !== "[object Object] [object Object]" ? props.imageData : {};
  return (
    <div className="nexus-row how-its-used-container nexus-pt-6 nexus-pb-5 nexus-px-7 nexus-mt-7">
      <div className="nexus-col-lg-12 nexus-mb-1">
        <h6 className="nexus-h6">{howItsUsedData.QuestionText}</h6>
      </div>
      <div className="nexus-col-lg-12 nexus-mb-3">
        {howItsUsedData.Header &&
          <h2 className="nexus-h2">
            <span className="bold-txt">
              {howItsUsedData.Header.split(" ").slice(0, 2).join(" ")}
            </span>{" "}
            {howItsUsedData.Header.split(" ")
              .slice(2, howItsUsedData.Header.length)
              .join(" ")}
          </h2>
        }
      </div>
      <div className="nexus-col-lg-12 nexus-mb-3">
        <h6 className="nexus-h6">{howItsUsedData.Description}</h6>
      </div>
      {imageData.length > 0 &&
        imageData.map((item: any) => {
          return (
            <div className="nexus-col-lg-5 how-its-used-cards-block" key={item.Title}>
              <NexusCard>
                <NexusCardBody>
                  <NexusCardBodyImage>
                    <img src={item.imageURL !== undefined ? item.imageURL : cardImage} alt="Card Image" style={{ height: "250px" }} />
                  </NexusCardBodyImage>
                  <h6
                    data-testid="body-with-image-body"
                    className="nexus-h6 nexus-mb-2"
                  >
                    {item.Title}
                  </h6>
                  <p
                    data-testid="body-with-image-bodyend"
                    className="nexus-mb-2 nexus-body-sm"
                  >
                    {item.Description}
                  </p>
                </NexusCardBody>
                <NexusCardFooter>
                  <div className="nexus-center-xs card-learn-more nexus-row">
                    <a href={`adopt-details/${item.ID}`} className="nexus-btn-fluid nexus-btn nexus-my-2">Learn More</a>
                  </div>
                </NexusCardFooter>
              </NexusCard>
            </div>
          );
        })}
    </div>
  );
};

export default HowItsUsedComponent;
