import React from "react";
import './Technical.scss'
import BadgeImg from '../../../../../common/assets/images/badge.png'
import { NexusButton } from "@nexus/react";

const TechnicalComponent = (props: any) => {
    console.log("🚀 ~ TechnicalComponent ~ props:", props)

    return (
        <>
            <h2 className="nexus-h2">Technical Training</h2>
            {props.trainData.map((item: any, idx: number) => (
                <div className="badge-container" key={idx}>
                    <div className="badge-image">
                        <img src={item.imgUrl} alt="badge" className="badge" />
                    </div>
                    <div className="badge-text-container">
                        <h4 className="badge-header">{item.Title}</h4>
                        <p className="nexus-h6">{item.Description}</p>
                        {item.ComingSoon ?
                            <div className="badge-coming-soon-tag"><span>Coming Soon</span></div>
                            :
                            <a href={`${item.TrainingLink}`} className="nexus-btn-primary nexus-btn-medium " target="_blank">Launch</a>
                        }
                    </div>
                </div>
            ))}

        </>
    )
}

export default TechnicalComponent