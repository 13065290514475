import { useEffect } from "react";
import FooterComponent from "./common/components/FooterComponent/FooterComponent";
import HeaderComponent from "./common/components/HeaderComponent/HeaderComponent";
import AppRoutes from "./common/routes/AppRoutes";
import "./common/styles/index.scss";
import { InteractionRequiredAuthError, InteractionStatus, PopupRequest } from "@azure/msal-browser";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { LoaderComponent } from "./common/components";

export interface IAppProps {
  description: string;
}

const App = () => {

  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const accountInfo = useAccount();

  useEffect(() => {
    console.log(accountInfo)
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      const loginRequest: PopupRequest = {
        scopes: ['openid', 'profile', 'User.Read'], // Add scopes as needed
        redirectUri: location.href
      };

      instance.loginRedirect(loginRequest).then((loginResponse: any) => {
        console.log(loginResponse);
        // Set the active account
        instance.setActiveAccount(loginResponse.account);
        // Now you can use the account info as needed
        console.log('Logged in user:', instance.getActiveAccount());
      }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          // Fallback to loginRedirect if loginPopup fails
          instance.loginRedirect(loginRequest).catch((redirectError) => {
            console.error(redirectError);
          });
        } else {
          console.error(error);
        }
      });

      instance.handleRedirectPromise().then((response) => {
        console.log(response);
      });
    }



  }, [instance, isAuthenticated, inProgress, accounts, accountInfo]);

  return (
    <div>
      {isAuthenticated ? (
        <div>
          <HeaderComponent />
          <AppRoutes />
          <FooterComponent />
        </div>
      ) : (
        <div>
          <HeaderComponent />
          <LoaderComponent />
          <FooterComponent />
        </div>
      )}
    </div>
  )
};

export default App;
